import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { IAuthRequest, IAuthResponse } from '../../workspace/core/types/auth.interface';
import { HttpClient } from '@angular/common/http';
import { LocalStorageKeys } from '../types/local-storage-keys.enum';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {IClientUser} from "../../workspace/core/types/client-user.interface";

@Injectable({
	providedIn: 'root'
})
export class AuthService extends BaseApiService {
	private _role: string;
	private _clientUser: IClientUser;


	public get token(): string {
		return localStorage.getItem(LocalStorageKeys.TOKEN);
	}

	public set token(value: string) {
		localStorage.setItem(LocalStorageKeys.TOKEN, value);
	}

	public get role(): string {
		return this._role;
	}

	public set role(value: string) {
		this._role = value;
	}

	public get user(): IClientUser {
		return this._clientUser;
	}

	public set user(value: IClientUser) {
		this._clientUser = value;
	}

	constructor(
		http: HttpClient,
		dialog: MatDialog,
		private _router: Router
	) {
		super(http, dialog);
	}

	public login(request: IAuthRequest): Observable<IAuthResponse> {
		return this.post<IAuthResponse>('/client/token', request).pipe(
			tap((response: IAuthResponse) => this.token = response.token)
		);
	}

	public logout(): void {
		localStorage.removeItem(LocalStorageKeys.TOKEN);
		this._router.navigate([ 'auth', 'login' ]);
	}
}
